'use client';
import Image from "next/image";
import Link from "next/link";
import { Stack, Grid, Typography } from '@mui/material';
import { Twitter as TwitterIcon, YouTube as YouTubeIcon } from '@mui/icons-material';
import { useTheme, ThemeProvider } from '@mui/material/styles';
import React from "react";
import Box from "@mui/material/Box";


const navigation = {
    lottery: [
        { name: 'Winner Distribution List', href: '/winner-distribution-list' },
        { name: 'Distribution Calculator', href: '/distribution-calculator' },
        { name: 'Best Choice', href: '/best-choice' },
        { name: 'About Us', href: '/about' },
    ],
    support: [
        { name: 'FAQ', href: '/faq' },
        { name: 'Contact Support', href: '/contact' },
    ],
    company: [
        
    ],
    legal: [
        { name: 'Privacy Policy', href: '/privacy-policy' },
        { name: 'Terms & Condition', href: '/terms-condition' },
    ],
    ftsotools: [
        { name: 'Wrap/Unwrap Tool', href: '/tools/wrap/' },
        { name: 'FLR/SGB Calculator', href: '/tools/coin-calculator/' },
        { name: 'Claim Rewards', href: '/tools/delegate-rewards/' },
        { name: 'Data Provider List', href: '/tools/ftso-data-providers/' },
        { name: 'FTSO Delegation', href: '/tools/wallet-delegate/' },

    ],
    social: [
        {
            name: 'Twitter',
            href: 'https://twitter.com/ftsolottery',
            icon: TwitterIcon,
        },
        {
            name: 'YouTube',
            href: 'https://www.youtube.com/channel/UC8b5v45d_PCJExa6qbdUhjA',
            icon: YouTubeIcon,
        },
    ],
}

const Footer = () => {
    const theme = useTheme();

    return (
        <Stack spacing={2} sx={{ flexGrow: 1 }}>
            <ThemeProvider theme={theme}>
                <Box
                    sx={{
                        display: 'flex',
                        width: '100%',
                        alignItems: 'center',
                        justifyContent: 'center',
                        bgcolor: (theme) => (theme.palette.mode === 'light' ? 'rgba(31, 41, 55, 1)' : '#333333'),
                        color: 'white',
                        borderRadius: 1,
                        p: 3,
                    }}
                >


                        <Grid container spacing={8}>
                            <Grid item xs={12} md={4}>
                                <Box display="flex" flexDirection="column" alignItems="center" justifyContent="center" textAlign="center">
                                    <Image src="/logo.png" alt="FTSOLottery" width={100} height={50} />
                                    <Typography className="text-sm leading-6 text-gray-600"></Typography>
                                    <div className="flex space-x-6 mt-2">
                                        {navigation.social.map((item) => (
                                            <Link key={item.name} href={item.href} target="_blank" className="text-gray-400 hover:text-gray-500">
                                                <span className="sr-only">{item.name}</span>
                                                <item.icon className="h-6 w-6" aria-hidden="true" />
                                            </Link>
                                        ))}
                                    </div>
                                </Box>
                            </Grid>
                            <Grid item xs={12} md={8} container spacing={6}>
                                <Grid item xs={6} md={3}>
                                    <Typography variant="h6">
                                        Lottery
                                    </Typography>
                                    <ul role="list" className="mt-6 space-y-4">
                                        {navigation.lottery.map((item) => (
                                            <li key={item.name}>
                                                <Link href={item.href}>
                                                    {item.name}
                                                </Link>
                                            </li>
                                        ))}
                                    </ul>
                                </Grid>
                                <Grid item xs={6} md={3}>
                                    <Typography variant="h6">
                                        FTSO Tools
                                    </Typography>
                                    <ul role="list" className="mt-6 space-y-4">
                                        {navigation.ftsotools.map((item) => (
                                            <li key={item.name}>
                                                <Link href={item.href}>
                                                    {item.name}
                                                </Link>
                                            </li>
                                        ))}
                                    </ul>
                                </Grid>
                                <Grid item xs={6} md={3}>
                                    <Typography variant="h6">
                                        Support
                                    </Typography>
                                    <ul role="list" className="mt-6 space-y-4">
                                        {navigation.support.map((item) => (
                                            <li key={item.name}>
                                                <Link href={item.href}>
                                                    {item.name}
                                                </Link>
                                            </li>
                                        ))}
                                    </ul>
                                </Grid>
                                <Grid item xs={6} md={3}>
                                    <Typography variant="h6">
                                        Legal
                                    </Typography>
                                    <ul role="list" className="mt-6 space-y-4">
                                        {navigation.legal.map((item) => (
                                            <li key={item.name}>
                                                <Link href={item.href}>
                                                    {item.name}
                                                </Link>
                                            </li>
                                        ))}
                                    </ul>
                                </Grid>
                            </Grid>
                            <Grid item xs={12} md={12} container>
                                <Box sx={{ display: 'flex', justifyContent: 'flex-end', paddingRight: '16px' }}>
                                    <Typography variant="body2" sx={{ fontSize: '0.75rem' }}>
                                        &copy; 2023 FTSOLottery, Inc. All rights reserved.
                                    </Typography>
                                </Box>
                            </Grid>
                        </Grid>

                </Box>

            </ThemeProvider>
        </Stack>
    );
};

export default Footer;
