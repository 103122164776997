'use client';
import React, { useState } from 'react';
import NextLink from 'next/link';
import { ConnectButton } from '@rainbow-me/rainbowkit';
import Image from "next/image";
import { usePathname } from 'next/navigation';
import MenuIcon from '@mui/icons-material/Menu';
import { Stack, MenuItem, Button, Container, Menu, Typography, IconButton, Toolbar, Box, AppBar } from '@mui/material';
import AccountBalanceWalletIcon from '@mui/icons-material/AccountBalanceWallet';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';

const navigation = [
    { name: 'Home/Pools', href: '/', current: true },
    { name: 'Rules', href: '/rules', current: false },
    { name: 'Winner', href: '/winner', current: false },
    { name: 'Rewards Calculator', href: '/distribution-calculator', current: false },
    { name: 'FTSO Tools', href: '/tools', current: false, subItems: [
            { name: 'Wrap/Unwrap', href: '/tools/wrap', current: false },
            { name: 'FLR/SGB Calculator', href: '/tools/coin-calculator', current: false },
            { name: 'Claim Rewards', href: '/tools/delegate-rewards', current: false },
            { name: 'Data Provider List', href: '/tools/ftso-data-providers/', current: false },
            { name: 'FTSO Delegation', href: '/tools/wallet-delegate/', current: false },
        ] },
]

const Header = () => {
    const pathname = usePathname();
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const [anchorElNav, setAnchorElNav] = React.useState<null | HTMLElement>(null);

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const updatedNavigation = () => {
        return navigation.map(item => ({
            ...item,
            current: item.href === pathname,
        }));
    };
    //const updatedNavigation = updateNavigation();
    const navigationData = updatedNavigation();
    const handleOpenNavMenu = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorElNav(event.currentTarget);
    };

    const handleCloseNavMenu = () => {
        setAnchorElNav(null);
    };

    return (
        <>
            <Stack spacing={2} sx={{ flexGrow: 1 }}>
                    <AppBar position="static" sx={{ bgcolor: theme => theme.palette.mode === 'light' ? 'rgba(31, 41, 55, 1)' : '#333333' }}>

                    <Container maxWidth="xl">
                            <Toolbar disableGutters>
                                <Typography
                                    variant="h6"
                                    noWrap
                                    component="a"
                                    href="#app-bar-with-responsive-menu"
                                    sx={{
                                        mr: 2,
                                        display: { xs: 'none', md: 'flex' },
                                        fontFamily: 'monospace',
                                        fontWeight: 700,
                                        letterSpacing: '.3rem',
                                        color: 'inherit',
                                        textDecoration: 'none',
                                    }}
                                >
                                    <Image
                                        src={'/logo.png'}
                                        alt="FTSOLottery"
                                        width={80}
                                        height={50}
                                    />
                                </Typography>

                                <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}>
                                    <IconButton
                                        size="large"
                                        aria-label="account of current user"
                                        aria-controls="menu-appbar"
                                        aria-haspopup="true"
                                        onClick={handleOpenNavMenu}
                                        color="inherit"
                                    >
                                        <MenuIcon />
                                    </IconButton>
                                    <Menu
                                        id="menu-appbar"
                                        anchorEl={anchorElNav}
                                        anchorOrigin={{
                                            vertical: 'bottom',
                                            horizontal: 'left',
                                        }}
                                        keepMounted
                                        transformOrigin={{
                                            vertical: 'top',
                                            horizontal: 'left',
                                        }}
                                        open={Boolean(anchorElNav)}
                                        onClose={handleCloseNavMenu}
                                        sx={{
                                            display: { xs: 'block', md: 'none' },
                                        }}
                                    >
                                        {/* Mobile Navigation */}
                                        {navigationData.map((item, index) => (
                                            <div key={index}>
                                                {item.subItems ? (
                                                    <>
                                                        <Typography
                                                            variant="h6"
                                                            sx={{ width: '100%', paddingLeft: 2, fontWeight: 'bold' }}
                                                        >
                                                            {item.name}
                                                        </Typography>
                                                        {item.subItems.map((subItem, subIndex) => (
                                                            <MenuItem
                                                                key={`${index}-${subIndex}-${subItem.name}`}
                                                                component={NextLink}
                                                                href={subItem.href}
                                                                passHref
                                                                onClick={handleCloseNavMenu}
                                                            >
                                                                <a>{subItem.name}</a>
                                                            </MenuItem>
                                                        ))}
                                                    </>
                                                ) : (
                                                    <MenuItem
                                                        key={`${index}-${item.name}`}
                                                        onClick={handleCloseNavMenu}
                                                        component={NextLink}
                                                        href={item.href}
                                                        passHref
                                                    >
                                                        <Typography textAlign="center">{item.name}</Typography>
                                                    </MenuItem>
                                                )}
                                            </div>
                                        ))}
                                    </Menu>
                                </Box>

                                <Typography
                                    variant="h5"
                                    noWrap
                                    component="a"
                                    href="#app-bar-with-responsive-menu"
                                    sx={{
                                        mr: 2,
                                        display: { xs: 'flex', md: 'none' },
                                        flexGrow: 1,
                                        fontFamily: 'monospace',
                                        fontWeight: 700,
                                        letterSpacing: '.3rem',
                                        color: 'inherit',
                                        textDecoration: 'none',
                                    }}
                                >
                                    <Image
                                        src={'/logo.png'}
                                        alt="FTSOLottery"
                                        width={60}
                                        height={40}
                                    />
                                </Typography>
                                <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }}>
                                    {/* Desktop Navigation */}
                                    {navigationData.map((item, index) => (
                                        <div key={index}>

                                            {item.subItems ? (
                                                <>
                                                    <Button
                                                        onClick={handleClick}
                                                        sx={{ my: 2, color: 'white', display: 'block' }}
                                                    >
                                                        {item.name}
                                                    </Button>
                                                    <Menu
                                                        id={`menu-${item.name}`}
                                                        anchorEl={anchorEl}
                                                        keepMounted
                                                        open={Boolean(anchorEl)}
                                                        onClose={handleClose}
                                                    >
                                                        {item.subItems.map((subItem) => (
                                                            <MenuItem
                                                                key={subItem.name}
                                                                component={NextLink}
                                                                href={subItem.href}
                                                                passHref
                                                                onClick={handleClose}
                                                            >
                                                                <a>{subItem.name}</a>
                                                            </MenuItem>
                                                        ))}
                                                    </Menu>
                                                </>
                                            ) : (
                                                <Button
                                                    component={NextLink}
                                                    href={item.href}
                                                    passHref
                                                    sx={{ my: 2, color: 'white', display: 'block' }}
                                                    onClick={handleCloseNavMenu}
                                                >
                                                    <a>{item.name}</a>
                                                </Button>
                                            )}

                                        </div>
                                    ))}
                                </Box>

                                <Box sx={{ flexGrow: 0 }}>
                                    <ConnectButton.Custom>
                                        {({
                                              account,
                                              chain,
                                              openAccountModal,
                                              openChainModal,
                                              openConnectModal,
                                              authenticationStatus,
                                              mounted,
                                          }) => {
                                            const ready = mounted && authenticationStatus !== 'loading';
                                            const connected =
                                                ready &&
                                                account &&
                                                chain &&
                                                (!authenticationStatus ||
                                                    authenticationStatus === 'authenticated');

                                            return (
                                                <div
                                                    {...(!ready && {
                                                        'aria-hidden': true,
                                                        'style': {
                                                            opacity: 0,
                                                            pointerEvents: 'none',
                                                            userSelect: 'none',
                                                        },
                                                    })}
                                                >
                                                    {(() => {
                                                        if (!connected) {
                                                            return (
                                                                <Button
                                                                    sx={{
                                                                        margin: '0.5rem 0rem',
                                                                        display: 'flex',
                                                                        alignItems: 'center',
                                                                        backgroundColor: 'rgb(180 83 9 / 1)',
                                                                        borderRadius: '0.375rem',
                                                                        color: '#ffffff',
                                                                        paddingLeft: '1.125rem',
                                                                        paddingRight: '1.25rem',
                                                                        paddingTop: '0.5rem',
                                                                        paddingBottom: '0.5rem',
                                                                        fontSize: '0.775rem',
                                                                        transition: 'background-color 150ms ease-in-out',
                                                                        '&:hover': {
                                                                            backgroundColor: 'rgb(200 93 19 / 1)',
                                                                        },
                                                                        '& .icon': {
                                                                            height: '1.2rem',
                                                                            width: '1.2rem',
                                                                            marginRight: '0.2rem',
                                                                        },
                                                                    }}
                                                                    onClick={openConnectModal}
                                                                    type="button"
                                                                >
                                                                    <AccountBalanceWalletIcon className="icon" />
                                                                    Connect Wallet
                                                                </Button>
                                                            );
                                                        }

                                                        if (chain.unsupported) {
                                                            return (
                                                                <Button
                                                                    sx={{
                                                                        margin: '8px',
                                                                        display: 'flex',
                                                                        alignItems: 'center',
                                                                        backgroundColor: '#ff4d4d',
                                                                        borderRadius: '4px',
                                                                        color: '#ffffff',
                                                                        padding: '8px 16px',
                                                                        fontSize: '14px',
                                                                        transition: 'background-color 150ms ease-in-out',
                                                                        '&:hover': {
                                                                            backgroundColor: '#cc3333',
                                                                        },
                                                                    }}
                                                                    onClick={openChainModal}
                                                                    type="button"
                                                                >
                                                                    <ErrorOutlineIcon
                                                                        sx={{
                                                                            height: '24px',
                                                                            width: '24px',
                                                                            marginRight: '8px',
                                                                        }}
                                                                    />
                                                                    Wrong Network
                                                                </Button>
                                                            );
                                                        }

                                                        return (

                                                                <Button
                                                                    sx={{
                                                                        margin: '0.5rem 0rem',
                                                                        display: 'flex',
                                                                        alignItems: 'center',
                                                                        backgroundColor: 'rgb(180, 83, 9, 1)',
                                                                        borderRadius: '0.375rem',
                                                                        color: '#ffffff',
                                                                        paddingLeft: '1.125rem',
                                                                        paddingRight: '1.25rem',
                                                                        paddingTop: '0.5rem',
                                                                        paddingBottom: '0.5rem',
                                                                        fontSize: '0.775rem',
                                                                        transition: 'background-color 150ms ease-in-out',
                                                                        '&:hover': {
                                                                            backgroundColor: 'rgb(200, 93, 19, 1)',
                                                                        },
                                                                        '& .icon': {
                                                                            height: '1.2rem',
                                                                            width: '1.2rem',
                                                                            marginRight: '0.2rem',
                                                                        },
                                                                    }}
                                                                    onClick={openAccountModal}
                                                                    type="button"
                                                                >

                                                                    <AccountCircleIcon className="icon" />

                                                                    {account.displayName}
                                                                </Button>

                                                        );
                                                    })()}
                                                </div>
                                            );
                                        }}
                                    </ConnectButton.Custom>
                                </Box>

                            </Toolbar>
                        </Container>
                    </AppBar>

            </Stack>

        </>
    );
};

export default Header;
